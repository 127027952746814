div.hero#hero{
    background-image: url('../../assets/imgs/maintenance.jpg');
    background-position: bottom;
    background-size: cover;


}
div.hero1#hero1{
    background-image: url('../../assets/imgs/newDr/gowtham-2.jpg');
    background-position: bottom;
    background-size: cover;
    background-attachment: fixed;    





}
section#bw{
    background-image: url('../../assets/imgs/dredgine3.png');
    background-position: bottom;
    background-size: cover;


}
div.partners_bg{
    /* background-image: url('../../assets/imgs/work2s.png'); */
    background-position: center;
    background-size: cover;


}
